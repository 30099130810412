*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
html {
  font-family: $font;
  font-size: 16px;
  line-height: 1.15;
  overflow-x: hidden;
  color: #3f3f3f;

  @include m768() {
    font-size: 15px;
  }
  @include m425() {
    font-size: 14px;
  }
}
body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 15px 0;
}
h1 {
  font-family: $font-bold;

  @include font-size(36);
  @include line-height(40);
}
h2 {
  font-family: $font-bold;
  text-transform: uppercase;
  color: $brand-light;

  @include font-size(24);
  @include line-height(30);
}
h3 {
  font-family: $font-bold;
  color: $brand-highlight;

  @include font-size(18);
  @include line-height(24);
}
h4 {
  font-family: $font-bold;
  color: $brand;

  @include font-size(18);
  @include line-height(24);
}
h5 {
  font-family: $font-bold;
  color: $brand;

  @include font-size(14);
  @include line-height(18);
}
h6 {
  font-family: $font-bold;
  text-transform: uppercase;
  color: $brand-light;

  @include font-size(14);
  @include line-height(18);
}
p {
  color: #1c3c6d;

  @include font-size(14);
  @include line-height(18);
}
a {
  transition: color .5s;
  color: $link-color;
  &:hover {
    text-decoration: none;
    color: $brand-highlight;
  }
}
ol,
ul {
  li {
    padding: 3px 0;
  }
}
ul {
  li {
    list-style-image: url('../img/ul.jpg');
  }
}
.main {
  position: relative;

  flex: 1 0 auto;
}
.container {
  position: relative;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 15px 30px;

  @include m768() {
    padding: 10px 20px;
  }
  @include m425() {
    padding: 10px;
  }
}
.header,
.page-header,
.footer {
  flex: 0 0 auto;
}

.section-header {
  text-align: center;
}
.section-header__title {
  font-family: $font-bold;
  margin: 10px 0 15px 0;
  text-transform: uppercase;
  color: $brand-light;

  @include font-size(36);
  @include line-height(40);
  @include m425() {
    margin-bottom: 10px;

    @include font-size(28);
    @include line-height(34);
  }
  span {
    color: $brand;
  }
}
.section-header__subtitle {
  font-family: $font;
  color: #403549;

  @include font-size(16);
}
.map {
  height: 390px;

  @include m768() {
    height: 300px;
  }
}

.link-btn {
  font-family: $font-semibold;
  display: inline-block;
  padding: 7px 15px;
  transition: .5s ;
  text-decoration: none;
  text-transform: uppercase;
  color: $brand;
  border: 2px solid $brand;
  border-top: none;
  border-bottom: none;

  @include font-size(16);
  &:hover {
    color: $brand-highlight;
    border-color: $brand-highlight;
  }
}

//begin left circle
.left-circle {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  margin: auto;
  .cont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 1150px;
    margin: auto;
    pointer-events: none;

    @include m1000() {
      display: none;
    }
  }
}
.blabla-circle,
.expert-reviews-circle {
  position: absolute;
  width: 1600px;
  height: 1600px;
  margin: auto;
  border: 170px solid rgba(43,107,166,.2);
  border-radius: 50%;
}
.blabla-circle {
  top: 100px;
  left: -1320px;
}

.expert-reviews-circle {
  bottom: 0;
  left: -1200px;
}
//end left circle
