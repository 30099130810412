%clearfix {
  &::before,
  &::after {
    display: table;
    content: '';
  }
  &::after {
    clear: both;
  }
}

// @mixin pt($style: regular) {
//   @if $style == regular {
//     font-family: 'PTSans-Regular', Arial, sans-serif;
//   }
//   @if $style == light {
//     font-family: 'PTSans-Italic', Arial, sans-serif;
//   }
//   @if $style == bold {
//     font-family: 'PTSans-Bold', Arial, sans-serif;
//   }
//   @if $style == bolditalic {
//     font-family: 'PTSans-BoldItalic', Arial, sans-serif;
//   }
// }

// ===== responsive =====
@mixin m1200 {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
@mixin m1100 {
  @media screen and (max-width: 1100px) {
    @content;
  }
}
@mixin m1024 {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin m1023 {
  @media screen and (max-width: 1023px) {
    @content;
  }
}
@mixin m1000 {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin m768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin m680 {
  @media screen and (max-width: 680px) {
    @content;
  }
}
@mixin m500 {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin m425 {
  @media screen and (max-width: 425px) {
    @content;
  }
}
@mixin m375 {
  @media screen and (max-width: 425px) {
    @content;
  }
}

// ===== mobile-first =====
@mixin mf1200 {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
@mixin mf1024 {
  @media screen and (min-width: 1024px) {
    @content;
  }
}
@mixin mf768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin mf425 {
  @media screen and (min-width: 425px) {
    @content;
  }
}
@mixin mf320 {
  @media screen and (min-width: 320px) {
    @content;
  }
}

// ===== placeholder color =====
@mixin placeholder-color($color: #5f5f5f) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

// ===== calculate font-size & line-height Px to Rem =====
@mixin font-size($sizeValue: 16) {
  // fallback for old browsers
  font-size: $sizeValue + px;
  // calculate px to rem
  font-size: ($sizeValue / 16) + rem;
}
@mixin line-height($heightValue: 16 ){
  // fallback for old browsers
  line-height: $heightValue + px;
  // calculate px to rem
  line-height: ($heightValue / 16) + rem;
}
