.calc-section {
  position: relative;
  z-index: 1;
  .section-header {
    margin-bottom: 40px;
  }
}
.calc-select-section {
  position: relative;
  z-index: 10;
  display: flex;
  padding: 35px 25px;
  background-color: #d6dfeb;

  align-items: flex-end;
  justify-content: space-around;
  @include m1000() {
    padding: 25px 15px 20px 15px;

    flex-wrap: wrap;
  }
}
.calc-select__item {
  min-width: 19%;
  margin-right: 1%;

  @include m1000() {
    min-width: 24%;
    margin-bottom: 30px;
  }
  @include m768() {
    width: 48%;
    margin-right: 1%;
    margin-left: 1%;
  }
  @include m500() {
    width: 98%;
  }
}

.calc-select__btn-wrap {
  width: 20%;

  @include m1000() {
    width: 100%;
    text-align: center;
  }
}

.calc-select__btn {
  font-family: $font-semibold;
  position: relative;
  display: inline-block;
  max-width: 195px;
  padding: 10px 50px 10px 20px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: $brand;
  &:hover {
    color: #fff;
    background-color: $brand-highlight;
  }
  svg {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: block;
    width: 25px;
    height: 30px;
    margin: auto;

    fill: #fff;
  }
}
.calc-select__title {
  font-family: $font-semibold;
  color: $brand;

  @include font-size(16);
}

.calc-tabs__list {
  display: flex;
  margin: 0;
  padding: 0;

  justify-content: space-between;
  @include m1100() {
    flex-wrap: wrap;
  }
}
.calc-tabs__item {
  font-family: $font-semibold;
  position: relative;
  margin-bottom: 20px;
  padding: 0;
  padding: 10px 20px;
  list-style: none;
  cursor: pointer;
  transition: all .5s;
  text-transform: uppercase;
  color: $brand;

  @include m1100() {
    width: 50%;
  }
  @include m680() {
    width: 100%;
  }
  &::before {
    position: absolute;
    right: 20px;
    bottom: 5px;
    left: 20px;
    display: block;
    height: 0;
    margin: auto;
    content: '';
    border-bottom: 2px dashed $brand;
  }
  &:hover {
    color: #fff;
    background-color: $brand;
    &::before {
      border-bottom: 2px dashed $brand;
    }
  }
  &.active {
    color: $brand-highlight;
    background-color: transparent;
    &:hover:before,
    &::before {
      border-bottom: 2px dashed $brand-highlight;
    }
  }
}



.calc-wrap {
  position: relative;
}
.calc {
  position: absolute;
  top: 0;
  left: -400%;
  overflow: hidden;
  transition: all .5s;
  &.active {
    position: relative;
    left: 0;
  }
}
// begin selectUI
.ui-button .ui-icon {
  width: 0;
  height: 0;
  margin-top: 5px;
  transition: .5s;
  border-top: 10px solid $brand;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  background: transparent;
  &:hover {
    border-top: 10px solid $brand-highlight;
  }
}
.ui-selectmenu-button.ui-button {
  width: 100%;
  // max-width: 90%;
  padding: 8px 10px 7px 20px;
  border-color: transparent;
  border-radius: 0;
  outline: none;
  background-color: #fff;

  @include m500() {
    max-width: 100%;
  }
}
.ui-selectmenu-button.ui-selectmenu-button-open {
  border-radius: 0;
}
.ui-selectmenu-text {
  font-size: 16px;
  color: #505050;
}
.ui-menu .ui-menu-item {
  padding: 0;
}
.ui-widget.ui-widget-content {
  font-size: 16px;
  color: #505050;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.ui-menu-item-wrapper {
  padding: 8px 10px 8px 20px !important;
}
.ui-menu-item-wrapper.ui-state-active {
  color: #fff;
  border-color: $brand;
  background-color: $brand;
}
// end selectUI

//begin calc-result
.calc-result__wrap {
  display: none;
  margin-bottom: 20px;
}
.calc-result {
  display: flex;
  margin-top: 30px;
  padding: 20px 0;
  background-color: $brand;

  justify-content: space-between;
  @include m1000() {
    flex-wrap: wrap;
  }
}
.calc-result__left {
  width: 300px;
  padding: 10px 10px 10px 30px;

  @include m1000() {
    width: 100%;
    text-align: center;
  }
}
.calc-result__left--subtitle {
  font-family: $font-semibold;
  letter-spacing: -.18px;
  text-transform: uppercase;
  color: #fff;

  @include font-size(18);
}
.calc-result__left--title {
  font-family: $font-bold;
  letter-spacing: -.36px;
  text-transform: uppercase;
  color: #fff;

  @include font-size(36);
}

.calc-result__list {
  display: flex;

  @include m1000() {
    width: 100%;
    padding: 0 20px 0 30px;
  }
  @include m768() {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.calc-result__item {
  position: relative;
  padding: 10px 30px 10px 0;

  @include m1000() {
    padding: 10px 30px 10px 0;
  }
  @include m768() {
    margin-bottom: 30px;
  }
  @include m500() {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }
  &::before {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: block;
    width: 1px;
    margin: auto;
    content: '';
    background-color: #618cbb;

    @include m500() {
      display: none;
    }
  }
  &:last-child {
    &::before {
      display: none;
    }
  }
}
.calc-result__item--text {
  font-family: $font;
  text-transform: none;
  color: #fff;

  @include font-size(14);
  @include line-height(16);
}
.calc-result__item--price {
  font-family: $font-bold;
  margin-bottom: 0;
  white-space: nowrap;
  color: #fff;

  @include font-size(24);
}
.calc-tab-02 {
  .calc-result__left {
    width: 380px;

    @include m1000() {
      width: 100%;
      text-align: center;
    }
  }
  .calc-result__left--title {
    font-family: $font-semibold;
    text-transform: none;

    @include font-size(30);
  }
}
//end calc-result


//begin calc-result__footer
.calc-result-footer {
  display: flex;
  padding: 10px;
  background-color: #183f87;

  align-items: center;
  @include m1000() {
    flex-wrap: wrap;
  }
}
.calc-result-footer__item {
  display: flex;
  width: 33.3%;

  align-items: center;
  justify-content: center;
  @include m1000() {
    width: 50%;
    margin-bottom: 30px;
  }
  @include m500() {
    width: 100%;
    margin-bottom: 10px;
  }
  svg {
    display: block;
    max-width: 45px;
    max-height: 45px;
    margin-right: 20px;

    fill: #fff;
  }
}
.calc-result-footer__text {
  font-family: $font-semibold;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;

  @include font-size(16);
  span {
    color: $brand-highlight;
  }
}
.calc-result-footer__btn-wrap {
  display: flex;
  width: 33.3%;

  justify-content: center;
  @include m1000() {
    width: 100%;
    margin-bottom: 20px;
  }
}
.calc-result-footer__btn {
  font-family: $font-semibold;
  position: relative;
  display: block;
  width: 100%;
  max-width: 210px;
  padding: 15px 50px 12px 20px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: $brand-light;

  align-items: center;
  @include font-size(16);
  @include line-height(18);
  &:hover {
    color: #fff;
    background-color: $brand-highlight;
  }
  svg {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 6px;
    display: block;
    max-width: 45px;
    max-height: 45px;
    margin: auto;

    fill: #fff;
  }
}
//end calc-result__footer
