.blabla {
  background-color: #f9fbfd;
  .container {
    display: flex;
    padding-bottom: 50px;
    padding-top: 40px;

    justify-content: space-between;
    @include m1000() {
      padding-bottom: 0;

      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

// begin blabla-left
.blabla-left {
  width: 40%;

  @include m1000() {
    width: 100%;
    max-width: 700px;
    margin-bottom: 50px;
  }
  @include m500() {
    margin-bottom: 20px;
  }
}
.blabla-left__title {
  font-family: $font-bold;
  letter-spacing: -.6px;
  text-transform: uppercase;
  color: $brand;

  @include font-size(30);
  @include m375() {
    @include font-size(24);
  }
}
.blabla-left__title--light {
  font-family: $font;
}
.blabla-left__title--highlight {
  color: $brand-light;
}
.blabla-left__subtitle {
  font-family: $font-semibold;
  margin-bottom: 40px;
  letter-spacing: -.6px;
  color: $brand;

  @include font-size(18);
  @include m1000() {
    margin-bottom: 20px;
  }
  @include m375() {
    @include font-size(16);
    @include line-height(16);
  }
}
.blabla-left__banner {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 440px;
  padding: 35px 25px;
  border: 2px solid $brand-highlight;
  background-color: #f9fbfd;
  box-shadow: 0 0 15px rgba(242, 108, 79, .22);

  @include m1000() {
    margin-right: auto;
    margin-left: auto;
  }
  @include m425() {
    padding: 25px 15px;
  }
  &::before {
    position: absolute;
    bottom: -50px;
    left: -30px;
    width: 210px;
    height: 120px;
    content: '';
    background-image: url('../img/blabla-banner-car.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;

    @include m500() {
      display: none;
    }
  }
}
.blabla-left__banner--left {
  @include m425() {
    display: none;
  }
  svg {
    display: block;
    width: 65px;
    height: 33px;

    fill: $brand-highlight;
  }
}
.blabla-left__banner-descr {
  padding-left: 25px;

  @include m425() {
    padding: 0;
    text-align: center;
  }
}
.blabla-left__banner-title {
  font-family: $font-bold;
  text-transform: uppercase;
  color: $brand-highlight;

  @include font-size(22);
}
.blabla-left__banner-text {
  margin-bottom: 0;
  letter-spacing: .5px;
  color: $brand;

  @include font-size(12);
  @include line-height(14);
}
// end blabla-left

// begin blabla-right
.blabla-right {
  display: flex;
  width: 55%;

  flex-wrap: wrap;
  align-items: flex-start;
  @include m1000() {
    width: 100%;
    max-width: 750px;
  }
}
.blabla-right__item {
  display: flex;
  width: 50%;
  padding: 10px 0 20px 10px;

  @include m500() {
    width: 100%;
    padding: 10px 20px;
  }
}
.blabla-right__item-left {
  svg {
    display: block;
    max-width: 56px;
    max-height: 56px;

    fill: $brand-light;
  }
}
.blabla-right__item-right {
  padding: 10px 0 10px 10px;
}
.blabla-right__title {
  font-family: $font-bold;
  letter-spacing: -.6px;
  text-transform: uppercase;
  color: $brand;

  @include font-size(16);
}
.blabla-right__text {
  font-family: $font-light;
  margin-bottom: 0;
  padding-right: 10px;
  color: $brand;

  @include font-size(11);
  @include line-height(12);
}
// end blabla-right
