@charset "UTF-8";
@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans/OpenSans.eot");
  src: url("../fonts/OpenSans/OpenSans.woff") format("woff"), url("../fonts/OpenSans/OpenSans.ttf") format("truetype"), url("../fonts/OpenSans/OpenSans.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Light';
  src: url("../fonts/OpenSans-Light/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light/OpenSans-Light.ttf") format("truetype"), url("../fonts/OpenSans-Light/OpenSans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Bold';
  src: url("../fonts/OpenSans-Bold/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold/OpenSans-Bold.ttf") format("truetype"), url("../fonts/OpenSans-Bold/OpenSans-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans-Semibold';
  src: url("../fonts/OpenSans-Semibold/OpenSans-Semibold.eot");
  src: url("../fonts/OpenSans-Semibold/OpenSans-Semibold.woff") format("woff"), url("../fonts/OpenSans-Semibold/OpenSans-Semibold.ttf") format("truetype"), url("../fonts/OpenSans-Semibold/OpenSans-Semibold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0; }

html {
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  line-height: 1.15;
  overflow-x: hidden;
  color: #3f3f3f; }
  @media screen and (max-width: 768px) {
    html {
      font-size: 15px; } }
  @media screen and (max-width: 425px) {
    html {
      font-size: 14px; } }

body {
  display: flex;
  flex-direction: column;
  min-width: 320px; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 15px 0; }

h1 {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 40px;
  line-height: 2.5rem; }

h2 {
  font-family: "OpenSans-Bold", sans-serif;
  text-transform: uppercase;
  color: #3f94ea;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 30px;
  line-height: 1.875rem; }

h3 {
  font-family: "OpenSans-Bold", sans-serif;
  color: #f26c4f;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem; }

h4 {
  font-family: "OpenSans-Bold", sans-serif;
  color: #305790;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem; }

h5 {
  font-family: "OpenSans-Bold", sans-serif;
  color: #305790;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }

h6 {
  font-family: "OpenSans-Bold", sans-serif;
  text-transform: uppercase;
  color: #3f94ea;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }

p {
  color: #1c3c6d;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }

a {
  transition: color .5s;
  color: #305790; }
  a:hover {
    text-decoration: none;
    color: #f26c4f; }

ol li,
ul li {
  padding: 3px 0; }

ul li {
  list-style-image: url("../img/ul.jpg"); }

.main {
  position: relative;
  flex: 1 0 auto; }

.container {
  position: relative;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 15px 30px; }
  @media screen and (max-width: 768px) {
    .container {
      padding: 10px 20px; } }
  @media screen and (max-width: 425px) {
    .container {
      padding: 10px; } }

.header,
.page-header,
.footer {
  flex: 0 0 auto; }

.section-header {
  text-align: center; }

.section-header__title {
  font-family: "OpenSans-Bold", sans-serif;
  margin: 10px 0 15px 0;
  text-transform: uppercase;
  color: #3f94ea;
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 40px;
  line-height: 2.5rem; }
  @media screen and (max-width: 425px) {
    .section-header__title {
      margin-bottom: 10px;
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 34px;
      line-height: 2.125rem; } }
  .section-header__title span {
    color: #305790; }

.section-header__subtitle {
  font-family: "OpenSans", sans-serif;
  color: #403549;
  font-size: 16px;
  font-size: 1rem; }

.map {
  height: 390px; }
  @media screen and (max-width: 768px) {
    .map {
      height: 300px; } }

.link-btn {
  font-family: "OpenSans-Semibold", sans-serif;
  display: inline-block;
  padding: 7px 15px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #305790;
  border: 2px solid #305790;
  border-top: none;
  border-bottom: none;
  font-size: 16px;
  font-size: 1rem; }
  .link-btn:hover {
    color: #f26c4f;
    border-color: #f26c4f; }

.left-circle {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  margin: auto; }
  .left-circle .cont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 1150px;
    margin: auto;
    pointer-events: none; }
    @media screen and (max-width: 1000px) {
      .left-circle .cont {
        display: none; } }

.blabla-circle,
.expert-reviews-circle {
  position: absolute;
  width: 1600px;
  height: 1600px;
  margin: auto;
  border: 170px solid rgba(43, 107, 166, 0.2);
  border-radius: 50%; }

.blabla-circle {
  top: 100px;
  left: -1320px; }

.expert-reviews-circle {
  bottom: 0;
  left: -1200px; }

.breadcrumbs__list {
  display: flex;
  margin: 0;
  padding: 0; }
  .breadcrumbs__list .breadcrumbs__item:last-child .breadcrumbs__link {
    text-decoration: none;
    pointer-events: none; }

.breadcrumbs__item {
  position: relative;
  list-style: none; }
  .breadcrumbs__item::after {
    margin: 0 5px 0 6px;
    content: '/';
    color: #fff;
    font-size: 12px;
    font-size: 0.75rem; }
  .breadcrumbs__item:last-child a {
    color: #3f94ea; }
  .breadcrumbs__item:last-child::after {
    display: none; }

.breadcrumbs__link {
  font-family: "OpenSans-Semibold", sans-serif;
  letter-spacing: .8px;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem; }
  .breadcrumbs__link:hover {
    text-decoration: none;
    color: #f26c4f; }

.layer {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .5s;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(28, 90, 158, 0.5); }
  .layer.active {
    pointer-events: auto;
    opacity: 1; }

.popup-box {
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  transition: opacity .5s;
  pointer-events: none;
  opacity: 0;
  align-items: center;
  justify-content: center; }
  .popup-box.active {
    opacity: 1; }
    .popup-box.active .popup {
      pointer-events: auto; }

.popup {
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  pointer-events: none;
  border: 4px solid #305790;
  background-color: #fff;
  align-items: center;
  justify-content: center; }

.popup__title {
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-size: 1.5rem; }

.popup__btn {
  font-family: "OpenSans-Semibold", sans-serif;
  display: inline-block;
  padding: 7px 40px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #305790;
  border: 2px solid #305790;
  border-top: none;
  border-bottom: none;
  outline: none;
  background-color: #fff;
  font-size: 16px;
  font-size: 1rem; }
  .popup__btn:hover {
    color: #f26c4f;
    border-color: #f26c4f; }

.toggle-mnu {
  position: absolute;
  top: 30px;
  left: 75px;
  width: 28px;
  height: 28px;
  text-decoration: none;
  text-transform: uppercase; }

.burger-menu {
  font-size: 18px;
  display: block;
  width: 100px;
  margin-left: -45px;
  padding-left: 45px;
  color: #fff; }

.burger {
  margin-top: -20px;
  margin-left: -45px; }

.burger:after,
.burger:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: ''; }

.burger:after {
  top: 18px; }

.burger {
  position: relative;
  display: block; }

.burger,
.burger:after,
.burger:before {
  width: 100%;
  height: 2px;
  transition: all .3s;
  border-radius: 2px;
  background-color: #fff;
  backface-visibility: hidden; }

.on .burger {
  background-color: transparent; }

.on .burger:before {
  transform: rotate(45deg) translate(-1px, 0px); }

.on .burger:after {
  transform: rotate(-45deg) translate(6px, -7px); }

.menu-wrap {
  position: relative; }

.main-menu {
  z-index: 9999;
  padding: 0; }
  .main-menu .menu__list {
    margin: 0; }
  .main-menu .menu__item {
    position: relative;
    transition: .5s;
    border-left: 2px solid transparent;
    background: none; }
    .main-menu .menu__item:hover, .main-menu .menu__item.sfHover {
      border-left: 2px solid #f26c4f;
      background: none; }
    .main-menu .menu__item.active {
      transition: .5s;
      transition-delay: .3s;
      border-left: 2px solid #3f94ea; }
      .main-menu .menu__item.active:hover {
        transition-delay: 0s;
        border-left: 2px solid #f26c4f; }
  .main-menu .menu__item--parent {
    position: relative;
    padding-right: 15px; }
    .main-menu .menu__item--parent::before, .main-menu .menu__item--parent::after {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      content: '';
      transition: .5s;
      background-color: #fff; }
    .main-menu .menu__item--parent::before {
      right: 27px;
      width: 10px;
      height: 2px; }
      @media screen and (max-width: 1100px) {
        .main-menu .menu__item--parent::before {
          right: 18px; } }
    .main-menu .menu__item--parent::after {
      right: 31px;
      width: 2px;
      height: 10px; }
      @media screen and (max-width: 1100px) {
        .main-menu .menu__item--parent::after {
          right: 22px; } }
    .main-menu .menu__item--parent:hover {
      background: none; }
      .main-menu .menu__item--parent:hover .menu__link {
        color: #f26c4f; }
      .main-menu .menu__item--parent:hover::before, .main-menu .menu__item--parent:hover::after {
        background-color: #f26c4f; }
  .main-menu .menu__sublist {
    width: auto;
    margin-left: -2px;
    padding-top: 5px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-left: 2px solid #f26c4f;
    background-color: #fff; }
  .main-menu .menu__subitem {
    background-color: #fff; }
    .main-menu .menu__subitem:hover {
      background-color: #fff; }
  .main-menu .menu__link {
    font-family: "OpenSans-Semibold", sans-serif;
    display: block;
    padding: 8px 30px 8px 10px;
    text-decoration: none;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: #fff;
    border: none;
    font-size: 14px;
    font-size: 0.875rem; }
    @media screen and (max-width: 1100px) {
      .main-menu .menu__link {
        padding: 8px 20px 8px 10px; } }
  .main-menu .menu__sublink {
    font-family: "OpenSans-Semibold", sans-serif;
    display: block;
    padding: 6px 15px 6px 10px;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;
    color: #305790;
    font-size: 14px;
    font-size: 0.875rem; }
  .main-menu .menu__link:hover, .main-menu .menu__link.active,
  .main-menu .menu__sublink:hover,
  .main-menu .menu__sublink.active {
    color: #f26c4f; }

.sf-menu {
  position: static;
  float: none; }
  .sf-menu li {
    position: static;
    white-space: normal; }
  .sf-menu > li > ul {
    left: auto;
    width: 220px; }
    .sf-menu > li > ul li .sf-with-ul::after {
      font-family: 'FontAwesome';
      position: absolute;
      right: 1em;
      content: ''; }

@media (min-width: 1024px) {
  .toggle-mnu,
  #menu,
  html.mm-blocking #mm-blocker {
    display: none; }
  html.mm-opening .mm-slideout {
    transform: translate(0px, 0px); } }

@media (max-width: 1023px) {
  .toggle-mnu {
    z-index: 10;
    display: block; }
  .sf-menu {
    display: none; } }

.clearfix::after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.'; }

.mm-menu.mm-effect-menu-slide {
  z-index: 9999; }

.mm-page.mm-slideout {
  height: 100%; }

.menu-wrap {
  margin: 0; }

.mm-panels {
  background-color: #305790; }

.mob-menu .mm-navbar,
.mob-menu .mob-menu__item,
.mob-menu .mob-menu__subitem {
  border-color: #3f94ea; }

.mob-menu .mm-title {
  font-size: 18px;
  transition: .5s;
  color: #fff !important; }
  .mob-menu .mm-title:hover {
    transition: .5s;
    color: #f26c4f !important; }

.mob-menu .mob-menu__link,
.mob-menu .mob-menu__sublink {
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-size: 1rem; }
  .mob-menu .mob-menu__link:hover, .mob-menu .mob-menu__link.active,
  .mob-menu .mob-menu__sublink:hover,
  .mob-menu .mob-menu__sublink.active {
    transition: .5s;
    color: #f26c4f; }

.mob-menu .mm-btn:hover::before {
  border-color: #f26c4f !important; }

.mob-menu .mm-next:hover::after {
  border-color: #f26c4f !important; }

.mm-menu.mm-theme-white .mm-listview > li .mm-arrow:after,
.mm-menu.mm-theme-white .mm-listview > li .mm-next:after,
.mm-menu.mm-theme-white .mm-navbar .mm-btn:after,
.mm-menu.mm-theme-white .mm-navbar .mm-btn:before {
  border-color: #fff; }

.footer {
  background-color: #f9fbfd;
  position: relative; }

.footer-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden; }
  @media screen and (max-width: 425px) {
    .footer-circle {
      display: none; } }
  .footer-circle::before {
    position: absolute;
    bottom: -500px;
    right: 75%;
    display: block;
    width: 1500px;
    height: 1500px;
    content: '';
    border: 180px solid rgba(219, 234, 241, 0.7);
    border-radius: 50%; }

.footer__banner {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: -60px auto 0;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .footer__banner {
      flex-direction: column-reverse; } }
  @media screen and (max-width: 425px) {
    .footer__banner {
      margin-top: -10px; } }

.footer-left {
  position: relative;
  width: 50%;
  padding: 25px 20px 25px 30px;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(63, 148, 234, 0.45); }
  @media screen and (max-width: 1000px) {
    .footer-left {
      padding: 15px 20px 10px 30px; } }
  @media screen and (max-width: 768px) {
    .footer-left {
      width: 90%;
      max-width: 550px; } }

.footer-contacts__title {
  font-family: "OpenSans-Bold", sans-serif;
  text-transform: uppercase;
  color: #3f94ea;
  font-size: 30px;
  font-size: 1.875rem; }

.footer-contacts__subtitle {
  font-family: "OpenSans-Semibold", sans-serif;
  text-transform: none;
  color: #305790;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }

.footer-contacts__item {
  display: flex;
  margin-bottom: 10px; }
  .footer-contacts__item svg {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    fill: #3f94ea; }

.footer-contacts__text p {
  margin: 0;
  color: #305790;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }
  .footer-contacts__text p span {
    font-family: "OpenSans-Bold", sans-serif; }

.footer-contacts__link {
  font-family: "OpenSans-Semibold", sans-serif;
  text-decoration: none; }

.footer-right {
  display: flex;
  width: 50%;
  box-shadow: 0 0 18px rgba(63, 148, 234, 0.45); }
  @media screen and (max-width: 768px) {
    .footer-right {
      width: 100%;
      max-width: 600px; } }
  @media screen and (max-width: 425px) {
    .footer-right {
      flex-direction: column; } }

.footer-form {
  width: 80%;
  padding: 35px 50px 35px 60px;
  background-color: #305790; }
  @media screen and (max-width: 1000px) {
    .footer-form {
      padding: 35px 30px 35px 30px; } }
  @media screen and (max-width: 425px) {
    .footer-form {
      width: 100%; } }

.footer-form__title {
  font-family: "OpenSans-Bold", sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  font-size: 1.875rem; }

.footer-form__subtitle {
  font-family: "OpenSans", sans-serif;
  text-transform: none;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }

.footer-form__input input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 10px;
  border: 1px solid transparent;
  border: 1px solid #305790;
  outline: none; }
  .footer-form__input input:focus, .footer-form__input input:active {
    border: 1px solid #3f94ea; }

.footer-form__submit input {
  font-family: "OpenSans-Semibold", sans-serif;
  padding: 5px 15px;
  transition: .5s;
  text-transform: uppercase;
  color: #fff;
  border: 2px solid #fff;
  border-top: none;
  border-bottom: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  font-size: 1rem; }
  .footer-form__submit input:hover {
    color: #f26c4f;
    border-color: #f26c4f; }

.footer-social {
  display: flex;
  flex-direction: column;
  width: 20%;
  background-color: #3f94ea;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 425px) {
    .footer-social {
      width: 100%;
      flex-direction: row; } }
  .footer-social svg {
    display: block;
    max-width: 60px;
    max-height: 60px;
    transition: fill .5s;
    fill: #305790; }
    .footer-social svg:hover {
      fill: #f26c4f; }

.footer-social__link {
  display: block;
  margin: 10px 20px; }

.footer-copyright {
  position: absolute;
  top: 101%;
  right: 0;
  left: 0;
  text-align: center; }
  .footer-copyright span,
  .footer-copyright a {
    margin: 0;
    text-decoration: none;
    color: #305790;
    font-size: 10px;
    font-size: 0.625rem; }

.footer-bottom {
  text-align: center;
  background-color: #fff; }

.footer-bottom__container {
  max-width: 750px;
  margin: 0 auto;
  padding: 10px; }

.footer-bottom__text {
  margin: 0;
  color: #305790;
  font-size: 10px;
  font-size: 0.625rem;
  line-height: 12px;
  line-height: 0.75rem; }

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  transition: all .5s;
  background-color: transparent; }
  .header .container {
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 1023px) {
      .header .container {
        padding-right: 30px;
        padding-left: 170px;
        justify-content: flex-end; } }
  .header.active {
    background-color: #305790;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.top-logo {
  display: block;
  margin-right: 20px; }
  @media screen and (max-width: 425px) {
    .top-logo {
      display: none; } }
  .top-logo img {
    display: block;
    max-width: 100%;
    max-height: 100%; }

.top-adress {
  display: flex;
  flex-direction: column; }

.top-adress__phone {
  display: flex;
  margin-bottom: 10px;
  white-space: nowrap;
  justify-content: flex-end;
  align-items: center; }
  .top-adress__phone a {
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    font-size: 1.5rem; }
    @media screen and (max-width: 1100px) {
      .top-adress__phone a {
        font-size: 20px;
        font-size: 1.25rem; } }
    .top-adress__phone a span {
      font-family: "OpenSans-Semibold", sans-serif; }

.top-adress__adress {
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  align-items: center; }
  .top-adress__adress:hover svg {
    fill: #f26c4f; }
  .top-adress__adress a {
    font-family: "OpenSans-Semibold", sans-serif;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-size: 1.125rem; }
    @media screen and (max-width: 1100px) {
      .top-adress__adress a {
        font-size: 14px;
        font-size: 0.875rem; } }
  .top-adress__adress svg {
    display: block;
    width: 20px;
    height: 25px;
    margin-right: 10px;
    transition: all .5s;
    fill: #fff; }

.header-bottom {
  padding-top: 80px;
  background-image: url("../img/bg-header-bottom.jpg");
  background-position: 50% 0;
  background-size: cover; }
  .header-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media screen and (max-width: 768px) {
      .header-bottom .container {
        flex-direction: column;
        align-items: flex-start; } }

.page-title {
  font-family: "OpenSans-Bold", sans-serif;
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  font-size: 1.875rem; }

.expert-reviews {
  position: relative;
  z-index: 1;
  display: flex; }
  @media screen and (max-width: 1000px) {
    .expert-reviews {
      flex-direction: column; } }

.expert {
  z-index: 1;
  display: flex;
  width: 50%;
  margin-top: 30px;
  background-image: url("../img/bg-expert.jpg");
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 0 0 30px rgba(63, 148, 234, 0.8);
  justify-content: flex-end; }
  @media screen and (max-width: 1000px) {
    .expert {
      width: 100%;
      margin-top: 0; } }

.expert__inn {
  width: 100%;
  max-width: 575px;
  padding: 40px 30px; }
  @media screen and (max-width: 1000px) {
    .expert__inn {
      margin: 0 auto; } }
  @media screen and (max-width: 500px) {
    .expert__inn {
      padding: 20px; } }

.expert__title {
  font-family: "OpenSans-Bold", sans-serif;
  margin-bottom: 45px;
  color: #fff;
  font-size: 36px;
  font-size: 2.25rem; }

.expert-slider__slide {
  display: flex !important;
  margin-bottom: 40px;
  outline: none; }

.expert-slider__pic {
  width: 130px; }
  .expert-slider__pic img {
    display: block; }

.expert-slider__text {
  padding-left: 20px; }

.expert-slider__name {
  font-family: "OpenSans", sans-serif;
  margin-bottom: 20px;
  color: #fff;
  font-size: 24px;
  font-size: 1.5rem; }

.expert-slider__subname {
  font-family: "OpenSans-Bold", sans-serif;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #f26c4f;
  font-size: 16px;
  font-size: 1rem; }

.expert-slider__quote {
  position: relative;
  padding-top: 20px;
  padding-left: 20px; }
  .expert-slider__quote::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 30px;
    height: 22px;
    content: '';
    background-image: url("../img/quotes.png");
    background-position: 50% 50%;
    background-size: cover; }
  .expert-slider__quote p {
    color: #fff;
    font-size: 16px;
    font-size: 1rem;
    line-height: 18px;
    line-height: 1.125rem; }

.expert-slider-nav {
  max-width: 300px;
  margin: 0 auto; }
  .expert-slider-nav .slick-list {
    padding: 0 !important; }
  .expert-slider-nav .slick-prev,
  .expert-slider-nav .slick-next {
    width: 16px;
    height: 29px;
    border: none;
    background-image: url("../img/slick/arrows.png"); }
    @media screen and (max-width: 425px) {
      .expert-slider-nav .slick-prev,
      .expert-slider-nav .slick-next {
        display: none !important; } }
    .expert-slider-nav .slick-prev::before,
    .expert-slider-nav .slick-next::before {
      display: none; }
  .expert-slider-nav .slick-prev {
    left: -60px;
    background-position: 0 -1px; }
    @media screen and (max-width: 500px) {
      .expert-slider-nav .slick-prev {
        left: -40px; } }
    @media screen and (max-width: 425px) {
      .expert-slider-nav .slick-prev {
        left: -25px; } }
    .expert-slider-nav .slick-prev:hover {
      background-position: 0 118px; }
  .expert-slider-nav .slick-next {
    right: -35px;
    background-position: -168px 0; }
    @media screen and (max-width: 500px) {
      .expert-slider-nav .slick-next {
        right: -15px; } }
    @media screen and (max-width: 425px) {
      .expert-slider-nav .slick-next {
        right: 0; } }
    .expert-slider-nav .slick-next:hover {
      background-position: -168px 118px; }

.expert-slider__thumb {
  outline: none; }
  .expert-slider__thumb img {
    width: 90%; }

.reviews {
  width: 50%;
  margin-bottom: 30px;
  background-image: url("../img/bg-reviews.jpg");
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 1000px) {
    .reviews {
      width: 100%;
      margin-bottom: 0; } }

.reviews__inn {
  width: 100%;
  max-width: 575px;
  padding: 40px 30px; }
  @media screen and (max-width: 1000px) {
    .reviews__inn {
      max-width: 800px;
      margin: 0 auto; } }
  @media screen and (max-width: 500px) {
    .reviews__inn {
      padding: 20px; } }

.reviews__title {
  font-family: "OpenSans-Bold", sans-serif;
  margin-bottom: 50px;
  color: #3f94ea;
  font-size: 36px;
  font-size: 2.25rem; }
  @media screen and (max-width: 500px) {
    .reviews__title {
      margin-bottom: 20px; } }

.reviews__quote {
  position: relative;
  padding-left: 30px; }
  .reviews__quote::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 15px;
    content: '';
    background-image: url("../img/quotes.png");
    background-position: 50% 50%;
    background-size: cover; }
  .reviews__quote p {
    color: #3c3c3c;
    font-size: 16px;
    font-size: 1rem;
    line-height: 18px;
    line-height: 1.125rem; }

.reviews__signature {
  font-family: "OpenSans-Semibold", sans-serif;
  margin-bottom: 30px;
  text-align: right;
  color: #305790;
  font-size: 18px;
  font-size: 1.125rem; }
  @media screen and (max-width: 500px) {
    .reviews__signature {
      margin-bottom: 20px; } }

.reviews__link {
  padding-left: 30px; }

.add-service .container {
  max-width: 1100px; }

.add-service-slider {
  display: flex; }

.add-service-slider__slide {
  display: flex !important;
  padding: 20px;
  align-items: center;
  flex-wrap: wrap; }

.add-service-slider__banner {
  width: 100%;
  padding: 30px 20px;
  transition: .5s;
  border: 2px solid #3f94ea; }

.add-service-slider__title {
  font-family: "OpenSans-Bold", sans-serif;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #305790;
  font-size: 18px;
  font-size: 1.125rem; }

.add-service-slider__text {
  margin: 0;
  color: #305790; }

.add-service-slider__pic {
  width: 50%;
  height: 90px;
  margin-top: -20px; }
  .add-service-slider__pic img {
    display: block;
    max-width: 100%;
    max-height: 100%; }

.add-service-slider__link {
  width: 50%;
  text-align: right; }
  .add-service-slider__link .link-btn {
    transition: .5s;
    color: #3f94ea;
    border-color: #3f94ea; }
    .add-service-slider__link .link-btn:hover {
      color: #f26c4f;
      border-color: #f26c4f; }

.add-service-slider .slick-prev,
.add-service-slider .slick-next {
  top: 37%;
  width: 16px;
  height: 29px;
  background-image: url("../img/slick/arrows-2.png"); }
  @media screen and (max-width: 425px) {
    .add-service-slider .slick-prev,
    .add-service-slider .slick-next {
      display: none !important; } }
  .add-service-slider .slick-prev::before,
  .add-service-slider .slick-next::before {
    display: none; }

.add-service-slider .slick-prev {
  background-position: 0 -1px; }
  @media screen and (max-width: 1024px) {
    .add-service-slider .slick-prev {
      left: -10px; } }
  .add-service-slider .slick-prev:hover {
    background-position: 0 100%; }

.add-service-slider .slick-next {
  background-position: 100% 100%; }
  @media screen and (max-width: 1024px) {
    .add-service-slider .slick-next {
      right: -10px; } }
  .add-service-slider .slick-next:hover {
    background-position: 100% -1px; }

.gallery-slide {
  display: block;
  width: 100%;
  max-width: 1200px;
  padding: 20px 0;
  outline: none; }
  .gallery-slide img {
    display: block;
    width: 100%;
    transition: 1s;
    filter: grayscale(1); }
  .gallery-slide.slick-center {
    position: relative;
    z-index: 10; }
    .gallery-slide.slick-center img {
      transform: scale(1.14);
      filter: none; }
      @media screen and (max-width: 768px) {
        .gallery-slide.slick-center img {
          transform: none; } }

.gallery-slider {
  width: 100% !important; }
  .gallery-slider .slick-prev,
  .gallery-slider .slick-next {
    top: 50%;
    width: 48px;
    height: 48px;
    background-image: url("../img/slick/arrows-3.png"); }
    @media screen and (max-width: 425px) {
      .gallery-slider .slick-prev,
      .gallery-slider .slick-next {
        display: none !important; } }
    .gallery-slider .slick-prev::before,
    .gallery-slider .slick-next::before {
      display: none; }
  .gallery-slider .slick-prev {
    z-index: 10;
    left: 33%;
    background-position: 0 0; }
    @media screen and (max-width: 1100px) {
      .gallery-slider .slick-prev {
        left: 20px; } }
    .gallery-slider .slick-prev:hover {
      background-position: 0 100%; }
  .gallery-slider .slick-next {
    right: 33%;
    background-position: 100% 0; }
    @media screen and (max-width: 1100px) {
      .gallery-slider .slick-next {
        right: 20px; } }
    .gallery-slider .slick-next:hover {
      background-position: 100% 100%; }

.blabla {
  background-color: #f9fbfd; }
  .blabla .container {
    display: flex;
    padding-bottom: 50px;
    padding-top: 40px;
    justify-content: space-between; }
    @media screen and (max-width: 1000px) {
      .blabla .container {
        padding-bottom: 0;
        flex-wrap: wrap;
        justify-content: center; } }

.blabla-left {
  width: 40%; }
  @media screen and (max-width: 1000px) {
    .blabla-left {
      width: 100%;
      max-width: 700px;
      margin-bottom: 50px; } }
  @media screen and (max-width: 500px) {
    .blabla-left {
      margin-bottom: 20px; } }

.blabla-left__title {
  font-family: "OpenSans-Bold", sans-serif;
  letter-spacing: -.6px;
  text-transform: uppercase;
  color: #305790;
  font-size: 30px;
  font-size: 1.875rem; }
  @media screen and (max-width: 425px) {
    .blabla-left__title {
      font-size: 24px;
      font-size: 1.5rem; } }

.blabla-left__title--light {
  font-family: "OpenSans", sans-serif; }

.blabla-left__title--highlight {
  color: #3f94ea; }

.blabla-left__subtitle {
  font-family: "OpenSans-Semibold", sans-serif;
  margin-bottom: 40px;
  letter-spacing: -.6px;
  color: #305790;
  font-size: 18px;
  font-size: 1.125rem; }
  @media screen and (max-width: 1000px) {
    .blabla-left__subtitle {
      margin-bottom: 20px; } }
  @media screen and (max-width: 425px) {
    .blabla-left__subtitle {
      font-size: 16px;
      font-size: 1rem;
      line-height: 16px;
      line-height: 1rem; } }

.blabla-left__banner {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 440px;
  padding: 35px 25px;
  border: 2px solid #f26c4f;
  background-color: #f9fbfd;
  box-shadow: 0 0 15px rgba(242, 108, 79, 0.22); }
  @media screen and (max-width: 1000px) {
    .blabla-left__banner {
      margin-right: auto;
      margin-left: auto; } }
  @media screen and (max-width: 425px) {
    .blabla-left__banner {
      padding: 25px 15px; } }
  .blabla-left__banner::before {
    position: absolute;
    bottom: -50px;
    left: -30px;
    width: 210px;
    height: 120px;
    content: '';
    background-image: url("../img/blabla-banner-car.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain; }
    @media screen and (max-width: 500px) {
      .blabla-left__banner::before {
        display: none; } }

@media screen and (max-width: 425px) {
  .blabla-left__banner--left {
    display: none; } }

.blabla-left__banner--left svg {
  display: block;
  width: 65px;
  height: 33px;
  fill: #f26c4f; }

.blabla-left__banner-descr {
  padding-left: 25px; }
  @media screen and (max-width: 425px) {
    .blabla-left__banner-descr {
      padding: 0;
      text-align: center; } }

.blabla-left__banner-title {
  font-family: "OpenSans-Bold", sans-serif;
  text-transform: uppercase;
  color: #f26c4f;
  font-size: 22px;
  font-size: 1.375rem; }

.blabla-left__banner-text {
  margin-bottom: 0;
  letter-spacing: .5px;
  color: #305790;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 14px;
  line-height: 0.875rem; }

.blabla-right {
  display: flex;
  width: 55%;
  flex-wrap: wrap;
  align-items: flex-start; }
  @media screen and (max-width: 1000px) {
    .blabla-right {
      width: 100%;
      max-width: 750px; } }

.blabla-right__item {
  display: flex;
  width: 50%;
  padding: 10px 0 20px 10px; }
  @media screen and (max-width: 500px) {
    .blabla-right__item {
      width: 100%;
      padding: 10px 20px; } }

.blabla-right__item-left svg {
  display: block;
  max-width: 56px;
  max-height: 56px;
  fill: #3f94ea; }

.blabla-right__item-right {
  padding: 10px 0 10px 10px; }

.blabla-right__title {
  font-family: "OpenSans-Bold", sans-serif;
  letter-spacing: -.6px;
  text-transform: uppercase;
  color: #305790;
  font-size: 16px;
  font-size: 1rem; }

.blabla-right__text {
  font-family: "OpenSans-Light", sans-serif;
  margin-bottom: 0;
  padding-right: 10px;
  color: #305790;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 12px;
  line-height: 0.75rem; }

.top-slider-section {
  position: relative;
  width: 100%;
  background-image: url("../img/bg-top-slider.jpg");
  background-size: cover; }
  .top-slider-section .container {
    padding-bottom: 0; }
  .top-slider-section .top-slider {
    margin-bottom: 0; }

.top-slider-dots {
  display: flex !important;
  margin: 0;
  padding: 0;
  justify-content: center; }
  .top-slider-dots li {
    margin: 0 15px;
    padding: 0;
    list-style: none; }
    .top-slider-dots li.slick-active button {
      background-color: #3f94ea; }
  .top-slider-dots button {
    width: 30px;
    height: 4px;
    transition: background-color .5s;
    text-indent: -9999px;
    border: none;
    outline: none;
    background-color: #fff; }
    .top-slider-dots button:hover {
      background-color: #f26c4f; }

.top-slider__slide {
  outline: none; }

.top-slider__left {
  width: 100%;
  max-width: 700px;
  padding: 110px 0 60px 50px; }
  @media screen and (max-width: 1100px) {
    .top-slider__left {
      padding: 110px 0 60px 20px; } }
  @media screen and (max-width: 1000px) {
    .top-slider__left {
      margin: 0 auto;
      padding: 110px 20px 40px 20px; } }
  @media screen and (max-width: 680px) {
    .top-slider__left {
      padding: 80px 20px 30px 20px; } }
  @media screen and (max-width: 500px) {
    .top-slider__left {
      padding: 80px 0 30px 0; } }
  .top-slider__left .link-btn {
    color: #fff;
    border-color: #fff; }
    .top-slider__left .link-btn:hover {
      color: #f26c4f;
      border-color: #f26c4f; }

.top-slider__title {
  font-family: "OpenSans-Bold", sans-serif;
  line-height: 1;
  margin-bottom: 0;
  letter-spacing: -.72px;
  color: #fff;
  font-size: 72px;
  font-size: 4.5rem; }
  @media screen and (max-width: 680px) {
    .top-slider__title {
      font-size: 60px;
      font-size: 3.75rem; } }
  @media screen and (max-width: 500px) {
    .top-slider__title {
      font-size: 46px;
      font-size: 2.875rem; } }

.top-slider__subtitle {
  font-family: "OpenSans-Bold", sans-serif;
  line-height: 1;
  letter-spacing: -.57px;
  text-transform: uppercase;
  color: #fff;
  font-size: 57px;
  font-size: 3.5625rem; }
  @media screen and (max-width: 680px) {
    .top-slider__subtitle {
      font-size: 40px;
      font-size: 2.5rem; } }
  @media screen and (max-width: 500px) {
    .top-slider__subtitle {
      font-size: 34px;
      font-size: 2.125rem; } }
  .top-slider__subtitle span {
    color: #3f94ea; }

.top-slider__text {
  font-family: "OpenSans-Semibold", sans-serif;
  max-width: 500px;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem; }
  @media screen and (max-width: 680px) {
    .top-slider__text {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 20px;
      line-height: 1.25rem; } }

.top-slider__slide-03 .top-slider__title {
  font-size: 60px;
  font-size: 3.75rem; }
  @media screen and (max-width: 680px) {
    .top-slider__slide-03 .top-slider__title {
      font-size: 54px;
      font-size: 3.375rem; } }

.top-slider__slide-03 .top-slider__subtitle {
  font-size: 48px;
  font-size: 3rem; }
  @media screen and (max-width: 680px) {
    .top-slider__slide-03 .top-slider__subtitle {
      font-size: 38px;
      font-size: 2.375rem; } }
  @media screen and (max-width: 500px) {
    .top-slider__slide-03 .top-slider__subtitle {
      font-size: 28px;
      font-size: 1.75rem; } }

.top-slider__slide-04 .top-slider__title {
  font-size: 60px;
  font-size: 3.75rem; }
  @media screen and (max-width: 500px) {
    .top-slider__slide-04 .top-slider__title {
      font-size: 50px;
      font-size: 3.125rem; } }

.top-slider__slide-04 .top-slider__subtitle {
  font-size: 72px;
  font-size: 4.5rem; }
  @media screen and (max-width: 500px) {
    .top-slider__slide-04 .top-slider__subtitle {
      font-size: 50px;
      font-size: 3.125rem; } }

.top-circle__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -200%;
  left: 0;
  overflow: hidden;
  width: 100%;
  margin: auto;
  pointer-events: none; }
  @media screen and (max-width: 1000px) {
    .top-circle__wrap {
      display: none; } }
  .top-circle__wrap .cont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 1150px;
    margin: auto; }

.top-circle {
  position: absolute;
  z-index: 10;
  top: -520px;
  right: -1320px;
  width: 1600px;
  height: 1600px;
  margin: auto;
  transition: 1s;
  border: 170px solid rgba(43, 107, 166, 0.2);
  border-radius: 50%; }
  .top-circle.active-0 {
    transform: rotate(0deg); }
  .top-circle.active-1 {
    transform: rotate(90deg); }
  .top-circle.active-2 {
    transform: rotate(180deg); }
  .top-circle.active-3 {
    transform: rotate(270deg); }

.top-circle__item {
  font-weight: 900;
  position: absolute;
  display: flex;
  width: 470px;
  height: 380px;
  margin: auto;
  color: #000;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-size: 1.25rem; }

.top-circle__item-00 {
  top: 0;
  bottom: 0;
  left: -360px;
  background-image: url("../img/top-slide-01.png"); }

.top-circle__item-01 {
  right: 0;
  bottom: -280px;
  left: 0;
  transform: rotate(-90deg);
  background-image: url("../img/top-slide-02.png"); }

.top-circle__item-02 {
  top: 0;
  right: -340px;
  bottom: 0;
  transform: rotate(-180deg);
  background-image: url("../img/top-slide-03.png"); }

.top-circle__item-03 {
  top: -320px;
  right: 0;
  left: 0;
  transform: rotate(-270deg);
  background-image: url("../img/top-slide-04.png"); }

.calc-section {
  position: relative;
  z-index: 1; }
  .calc-section .section-header {
    margin-bottom: 40px; }

.calc-select-section {
  position: relative;
  z-index: 10;
  display: flex;
  padding: 35px 25px;
  background-color: #d6dfeb;
  align-items: flex-end;
  justify-content: space-around; }
  @media screen and (max-width: 1000px) {
    .calc-select-section {
      padding: 25px 15px 20px 15px;
      flex-wrap: wrap; } }

.calc-select__item {
  min-width: 19%;
  margin-right: 1%; }
  @media screen and (max-width: 1000px) {
    .calc-select__item {
      min-width: 24%;
      margin-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    .calc-select__item {
      width: 48%;
      margin-right: 1%;
      margin-left: 1%; } }
  @media screen and (max-width: 500px) {
    .calc-select__item {
      width: 98%; } }

.calc-select__btn-wrap {
  width: 20%; }
  @media screen and (max-width: 1000px) {
    .calc-select__btn-wrap {
      width: 100%;
      text-align: center; } }

.calc-select__btn {
  font-family: "OpenSans-Semibold", sans-serif;
  position: relative;
  display: inline-block;
  max-width: 195px;
  padding: 10px 50px 10px 20px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #305790; }
  .calc-select__btn:hover {
    color: #fff;
    background-color: #f26c4f; }
  .calc-select__btn svg {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: block;
    width: 25px;
    height: 30px;
    margin: auto;
    fill: #fff; }

.calc-select__title {
  font-family: "OpenSans-Semibold", sans-serif;
  color: #305790;
  font-size: 16px;
  font-size: 1rem; }

.calc-tabs__list {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between; }
  @media screen and (max-width: 1100px) {
    .calc-tabs__list {
      flex-wrap: wrap; } }

.calc-tabs__item {
  font-family: "OpenSans-Semibold", sans-serif;
  position: relative;
  margin-bottom: 20px;
  padding: 0;
  padding: 10px 20px;
  list-style: none;
  cursor: pointer;
  transition: all .5s;
  text-transform: uppercase;
  color: #305790; }
  @media screen and (max-width: 1100px) {
    .calc-tabs__item {
      width: 50%; } }
  @media screen and (max-width: 680px) {
    .calc-tabs__item {
      width: 100%; } }
  .calc-tabs__item::before {
    position: absolute;
    right: 20px;
    bottom: 5px;
    left: 20px;
    display: block;
    height: 0;
    margin: auto;
    content: '';
    border-bottom: 2px dashed #305790; }
  .calc-tabs__item:hover {
    color: #fff;
    background-color: #305790; }
    .calc-tabs__item:hover::before {
      border-bottom: 2px dashed #305790; }
  .calc-tabs__item.active {
    color: #f26c4f;
    background-color: transparent; }
    .calc-tabs__item.active:hover:before, .calc-tabs__item.active::before {
      border-bottom: 2px dashed #f26c4f; }

.calc-wrap {
  position: relative; }

.calc {
  position: absolute;
  top: 0;
  left: -400%;
  overflow: hidden;
  transition: all .5s; }
  .calc.active {
    position: relative;
    left: 0; }

.ui-button .ui-icon {
  width: 0;
  height: 0;
  margin-top: 5px;
  transition: .5s;
  border-top: 10px solid #305790;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  background: transparent; }
  .ui-button .ui-icon:hover {
    border-top: 10px solid #f26c4f; }

.ui-selectmenu-button.ui-button {
  width: 100%;
  padding: 8px 10px 7px 20px;
  border-color: transparent;
  border-radius: 0;
  outline: none;
  background-color: #fff; }
  @media screen and (max-width: 500px) {
    .ui-selectmenu-button.ui-button {
      max-width: 100%; } }

.ui-selectmenu-button.ui-selectmenu-button-open {
  border-radius: 0; }

.ui-selectmenu-text {
  font-size: 16px;
  color: #505050; }

.ui-menu .ui-menu-item {
  padding: 0; }

.ui-widget.ui-widget-content {
  font-size: 16px;
  color: #505050;
  border: none;
  border-radius: 0;
  background-color: #fff; }

.ui-menu-item-wrapper {
  padding: 8px 10px 8px 20px !important; }

.ui-menu-item-wrapper.ui-state-active {
  color: #fff;
  border-color: #305790;
  background-color: #305790; }

.calc-result__wrap {
  display: none;
  margin-bottom: 20px; }

.calc-result {
  display: flex;
  margin-top: 30px;
  padding: 20px 0;
  background-color: #305790;
  justify-content: space-between; }
  @media screen and (max-width: 1000px) {
    .calc-result {
      flex-wrap: wrap; } }

.calc-result__left {
  width: 300px;
  padding: 10px 10px 10px 30px; }
  @media screen and (max-width: 1000px) {
    .calc-result__left {
      width: 100%;
      text-align: center; } }

.calc-result__left--subtitle {
  font-family: "OpenSans-Semibold", sans-serif;
  letter-spacing: -.18px;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem; }

.calc-result__left--title {
  font-family: "OpenSans-Bold", sans-serif;
  letter-spacing: -.36px;
  text-transform: uppercase;
  color: #fff;
  font-size: 36px;
  font-size: 2.25rem; }

.calc-result__list {
  display: flex; }
  @media screen and (max-width: 1000px) {
    .calc-result__list {
      width: 100%;
      padding: 0 20px 0 30px; } }
  @media screen and (max-width: 768px) {
    .calc-result__list {
      flex-wrap: wrap;
      justify-content: center; } }

.calc-result__item {
  position: relative;
  padding: 10px 30px 10px 0; }
  @media screen and (max-width: 1000px) {
    .calc-result__item {
      padding: 10px 30px 10px 0; } }
  @media screen and (max-width: 768px) {
    .calc-result__item {
      margin-bottom: 30px; } }
  @media screen and (max-width: 500px) {
    .calc-result__item {
      width: 100%;
      margin-bottom: 0;
      text-align: center; } }
  .calc-result__item::before {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: block;
    width: 1px;
    margin: auto;
    content: '';
    background-color: #618cbb; }
    @media screen and (max-width: 500px) {
      .calc-result__item::before {
        display: none; } }
  .calc-result__item:last-child::before {
    display: none; }

.calc-result__item--text {
  font-family: "OpenSans", sans-serif;
  text-transform: none;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 16px;
  line-height: 1rem; }

.calc-result__item--price {
  font-family: "OpenSans-Bold", sans-serif;
  margin-bottom: 0;
  white-space: nowrap;
  color: #fff;
  font-size: 24px;
  font-size: 1.5rem; }

.calc-tab-02 .calc-result__left {
  width: 380px; }
  @media screen and (max-width: 1000px) {
    .calc-tab-02 .calc-result__left {
      width: 100%;
      text-align: center; } }

.calc-tab-02 .calc-result__left--title {
  font-family: "OpenSans-Semibold", sans-serif;
  text-transform: none;
  font-size: 30px;
  font-size: 1.875rem; }

.calc-result-footer {
  display: flex;
  padding: 10px;
  background-color: #183f87;
  align-items: center; }
  @media screen and (max-width: 1000px) {
    .calc-result-footer {
      flex-wrap: wrap; } }

.calc-result-footer__item {
  display: flex;
  width: 33.3%;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 1000px) {
    .calc-result-footer__item {
      width: 50%;
      margin-bottom: 30px; } }
  @media screen and (max-width: 500px) {
    .calc-result-footer__item {
      width: 100%;
      margin-bottom: 10px; } }
  .calc-result-footer__item svg {
    display: block;
    max-width: 45px;
    max-height: 45px;
    margin-right: 20px;
    fill: #fff; }

.calc-result-footer__text {
  font-family: "OpenSans-Semibold", sans-serif;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-size: 1rem; }
  .calc-result-footer__text span {
    color: #f26c4f; }

.calc-result-footer__btn-wrap {
  display: flex;
  width: 33.3%;
  justify-content: center; }
  @media screen and (max-width: 1000px) {
    .calc-result-footer__btn-wrap {
      width: 100%;
      margin-bottom: 20px; } }

.calc-result-footer__btn {
  font-family: "OpenSans-Semibold", sans-serif;
  position: relative;
  display: block;
  width: 100%;
  max-width: 210px;
  padding: 15px 50px 12px 20px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #3f94ea;
  align-items: center;
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem; }
  .calc-result-footer__btn:hover {
    color: #fff;
    background-color: #f26c4f; }
  .calc-result-footer__btn svg {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 6px;
    display: block;
    max-width: 45px;
    max-height: 45px;
    margin: auto;
    fill: #fff; }

.banner {
  position: relative;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 50px; }
  .banner .container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    background-image: url("../img/bg-banner.jpg");
    background-position: 100% 50%;
    background-size: cover;
    box-shadow: 0 0 30px rgba(63, 148, 234, 0.5); }
    @media screen and (max-width: 1000px) {
      .banner .container {
        background-image: url("../img/bg-banner-small.jpg");
        justify-content: space-around; } }
    @media screen and (max-width: 680px) {
      .banner .container {
        flex-direction: column-reverse; } }

.banner-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  margin-right: 30px;
  padding-top: 10px;
  justify-content: space-between; }
  @media screen and (max-width: 680px) {
    .banner-left {
      max-width: 100%;
      text-align: center; } }

.banner__title {
  font-family: "OpenSans-Bold", sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 30px;
  line-height: 1.875rem; }
  @media screen and (max-width: 500px) {
    .banner__title {
      font-size: 26px;
      font-size: 1.625rem; } }

.banner-footer {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 500px) {
    .banner-footer {
      flex-direction: column; } }

.banner-footer__phone {
  font-family: "OpenSans-Bold", sans-serif;
  margin-bottom: 0;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
  font-size: 1.5rem; }
  @media screen and (max-width: 500px) {
    .banner-footer__phone {
      margin-bottom: 10px; } }

.banner-footer__text {
  font-family: "OpenSans-Semibold", sans-serif;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem; }
  .banner-footer__text span {
    color: #f26c4f; }

.banner-right {
  display: flex;
  justify-content: center;
  align-items: center; }

.banner-sale {
  display: flex;
  flex-direction: column;
  width: 155px;
  height: 155px;
  border: 5px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 1100px) {
    .banner-sale {
      border: 5px solid #f26c4f; } }
  @media screen and (max-width: 680px) {
    .banner-sale {
      margin-bottom: 10px; } }

.banner-sale__num {
  font-family: "OpenSans-Bold", sans-serif;
  margin-bottom: 10px;
  color: #fff;
  font-size: 48px;
  font-size: 3rem; }
  @media screen and (max-width: 1100px) {
    .banner-sale__num {
      color: #f26c4f; } }

.banner-sale__text {
  font-family: "OpenSans-Semibold", sans-serif;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
  font-size: 1.5rem; }
  @media screen and (max-width: 1100px) {
    .banner-sale__text {
      color: #f26c4f; } }
