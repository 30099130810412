.add-service {
  .container {
    max-width: 1100px;
  }
}
.add-service-slider {
  display: flex;
}
.add-service-slider__slide {
  display: flex !important;
  padding: 20px;

  align-items: center;
  flex-wrap: wrap;
}
.add-service-slider__banner {
  width: 100%;
  padding: 30px 20px;
  transition: .5s;
  border: 2px solid $brand-light;
}
.add-service-slider__title {
  font-family: $font-bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: $brand;

  @include font-size(18);
}
.add-service-slider__text {
  margin: 0;
  color: $brand;
}
.add-service-slider__pic {
  width: 50%;
  height: 90px;
  margin-top: -20px;
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
.add-service-slider__link {
  width: 50%;
  text-align: right;
  .link-btn {
    transition: .5s;
    color: $brand-light;
    border-color: $brand-light;
    &:hover {
      color: $brand-highlight;
      border-color: $brand-highlight;
    }
  }
}

// begin add-service-slider (edit default style)
.add-service-slider {
  .slick-prev,
  .slick-next {
    top: 37%;
    width: 16px;
    height: 29px;
    background-image: url('../img/slick/arrows-2.png');

    @include m375() {
      display: none !important;
    }
    &::before {
      display: none;
    }
  }
  .slick-prev {
    background-position: 0 -1px;

    @include m1024() {
      left: -10px;
    }
    &:hover {
      background-position: 0 100%;
    }
  }
  .slick-next {
    background-position: 100% 100%;

    @include m1024() {
      right: -10px;
    }
    &:hover {
      background-position: 100% -1px;
    }
  }
}
// end add-service-slider (edit default style)
