.expert-reviews {
  position: relative;
  z-index: 1;
  display: flex;

  @include m1000() {
    flex-direction: column;
  }
}
.expert {
  z-index: 1;
  display: flex;
  width: 50%;
  margin-top: 30px;
  background-image: url('../img/bg-expert.jpg');
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 0 0 30px rgba(63, 148, 234, .8);

  justify-content: flex-end;
  @include m1000() {
    width: 100%;
    margin-top: 0;
  }
}
.expert__inn {
  width: 100%;
  max-width: 575px;
  padding: 40px 30px;

  @include m1000() {
    margin: 0 auto;
  }
  @include m500() {
    padding: 20px;
  }
}
.expert__title {
  font-family: $font-bold;
  margin-bottom: 45px;
  color: #fff;

  @include font-size(36);
}

// begin expert-slider
.expert-slider__slide {
  display: flex !important;
  margin-bottom: 40px;
  outline: none;
}
.expert-slider__pic {
  width: 130px;
  img {
    display: block;
  }
}
.expert-slider__text {
  padding-left: 20px;
}
.expert-slider__name {
  font-family: $font;
  margin-bottom: 20px;
  color: #fff;

  @include font-size(24);
}
.expert-slider__subname {
  font-family: $font-bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: $brand-highlight;

  @include font-size(16);
}
.expert-slider__quote {
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 30px;
    height: 22px;
    content: '';
    background-image: url('../img/quotes.png');
    background-position: 50% 50%;
    background-size: cover;
  }
  p {
    color: #fff;

    @include font-size(16);
    @include line-height(18);
  }
}
.expert-slider-nav {
  max-width: 300px;
  margin: 0 auto;
  .slick-list {
    padding: 0 !important;
  }
  .slick-prev,
  .slick-next {
    width: 16px;
    height: 29px;
    border: none;
    background-image: url('../img/slick/arrows.png');

    @include m375() {
      display: none !important;
    }
    &::before {
      display: none;
    }
  }
  .slick-prev {
    left: -60px;
    background-position: 0 -1px;

    @include m500() {
      left: -40px;
    }
    @include m425() {
      left: -25px;
    }
    &:hover {
      background-position: 0 118px;
    }
  }
  .slick-next {
    right: -35px;
    background-position: -168px 0;

    @include m500() {
      right: -15px;
    }
    @include m425() {
      right: 0;
    }
    &:hover {
      background-position: -168px 118px;
    }
  }
}
.expert-slider__thumb {
  outline: none;
  img {
    width: 90%;
  }
}
// end expert-slider

.reviews {
  width: 50%;
  margin-bottom: 30px;
  background-image: url('../img/bg-reviews.jpg');
  background-position: 50% 50%;
  background-size: cover;

  @include m1000() {
    width: 100%;
    margin-bottom: 0;
  }
}
.reviews__inn {
  width: 100%;
  max-width: 575px;
  padding: 40px 30px;

  @include m1000() {
    max-width: 800px;
    margin: 0 auto;
  }
  @include m500() {
    padding: 20px;
  }
}
.reviews__title {
  font-family: $font-bold;
  margin-bottom: 50px;
  color: $brand-light;

  @include font-size(36);
  @include m500() {
    margin-bottom: 20px;
  }
}
.reviews__quote {
  position: relative;
  padding-left: 30px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 15px;
    content: '';
    background-image: url('../img/quotes.png');
    background-position: 50% 50%;
    background-size: cover;
  }
  p {
    color: #3c3c3c;

    @include font-size(16);
    @include line-height(18);
  }
}
.reviews__signature {
  font-family: $font-semibold;
  margin-bottom: 30px;
  text-align: right;
  color: $brand;

  @include font-size(18);
  @include m500() {
    margin-bottom: 20px;
  }
}
.reviews__link {
  padding-left: 30px;
}
