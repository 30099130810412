.banner {
  position: relative;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 50px;
  .container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    background-image: url('../img/bg-banner.jpg');
    background-position: 100% 50%;
    background-size: cover;
    box-shadow: 0 0 30px rgba(63, 148, 234, .5);

    @include m1000() {
    background-image: url('../img/bg-banner-small.jpg');
    justify-content: space-around;

    }
    @include m680() {
      flex-direction: column-reverse;
    }
  }
}
.banner-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  margin-right: 30px;
  padding-top: 10px;

  justify-content: space-between;
  @include m680() {
    max-width: 100%;
    text-align: center;
  }
}
.banner__title {
  font-family: $font-bold;
  text-transform: uppercase;
  color: #fff;

  @include font-size(30);
  @include line-height(30);
  @include m500() {
    @include font-size(26);
  }
}
.banner-footer {
  display: flex;
  padding-bottom: 20px;

  justify-content: space-between;
  align-items: center;
  @include m500() {
    flex-direction: column;
  }
}
.banner-footer__phone {
  font-family: $font-bold;
  margin-bottom: 0;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;

  @include font-size(24);
  @include m500() {
    margin-bottom: 10px;
  }
}
.banner-footer__text {
  font-family: $font-semibold;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;

  @include font-size(18);
  span {
    color: $brand-highlight;
  }
}
.banner-right {
  display: flex;

  justify-content: center;
  align-items: center;
}
.banner-sale {
  display: flex;
  flex-direction: column;
  width: 155px;
  height: 155px;
  border: 5px solid #fff;
  border-radius: 50%;

  justify-content: center;
  align-items: center;
  @include m1100() {
  border: 5px solid $brand-highlight;

  }
  @include m680() {
    margin-bottom: 10px;
  }
}
.banner-sale__num {
  font-family: $font-bold;
  margin-bottom: 10px;
  color: #fff;

  @include font-size(48);
  @include m1100() {
  color: $brand-highlight;

  }
}
.banner-sale__text {
  font-family: $font-semibold;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;

  @include font-size(24);
  @include m1100() {
  color: $brand-highlight;

  }
}
