.mm-panels {
  background-color: $brand;
}
.mob-menu {
  .mm-navbar,
  .mob-menu__item,
  .mob-menu__subitem {
    border-color: $brand-light;
  }
  .mm-title {
    font-size: 18px;
    transition: .5s;
    color: #fff !important;
    &:hover {
      transition: .5s;
      color: $brand-highlight !important;
    }
  }
  .mob-menu__link,
  .mob-menu__sublink {
    transition: .5s;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;

    @include font-size(16);
    &:hover,
    &.active {
      transition: .5s;
      color: $brand-highlight;
    }
  }
  .mm-btn {
    &:hover {
      &::before {
        border-color: $brand-highlight !important;
      }
    }
  }
  .mm-next {
    &:hover {
      &::after {
        border-color: $brand-highlight !important;
      }
    }
  }
}
.mm-menu.mm-theme-white .mm-listview > li .mm-arrow:after,
.mm-menu.mm-theme-white .mm-listview > li .mm-next:after,
.mm-menu.mm-theme-white .mm-navbar .mm-btn:after,
.mm-menu.mm-theme-white .mm-navbar .mm-btn:before {
  border-color: #fff;
}
