//Toggle Menu http://codepen.io/agragregra/pen/bEbbmZ
.toggle-mnu {
  position: absolute;
  top: 30px;
  left: 75px;
  width: 28px;
  height: 28px;
  text-decoration: none;
  text-transform: uppercase;
}
.burger-menu {
  font-size: 18px;
  display: block;
  width: 100px;
  margin-left: -45px;
  padding-left: 45px;
  color: #fff;
}
.burger {
  margin-top: -20px;
  margin-left: -45px;
}
.burger:after,
.burger:before {
  position: absolute;
  top: 9px;
  left: 0;
  content: '';
}
.burger:after {
  top: 18px;
}
.burger {
  position: relative;
  display: block;
}
.burger,
.burger:after,
.burger:before {
  width: 100%;
  height: 2px;
  transition: all .3s;
  border-radius: 2px;
  background-color: #fff;

  backface-visibility: hidden;
}
&.on .burger {
  background-color: transparent;
}
&.on .burger:before {
  transform: rotate(45deg) translate(-1px, 0px);
}
&.on .burger:after {
  transform: rotate(-45deg) translate(6px, -7px);
}
