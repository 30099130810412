.layer {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .5s;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(28,90,158,.5);
  &.active {
    pointer-events: auto;
    opacity: 1;
  }
}
.popup-box {
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  transition: opacity .5s;
  pointer-events: none;
  opacity: 0;

  align-items: center;
  justify-content: center;
  &.active {
    opacity: 1;
    .popup {
      pointer-events: auto;
    }
  }
}
.popup {
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  pointer-events: none;
  border: 4px solid $brand;
  background-color: #fff;

  align-items: center;
  justify-content: center;
}
.popup__title {
  line-height: 1.2;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;

  @include font-size(24);
}
.popup__btn {
  font-family: $font-semibold;
  display: inline-block;
  padding: 7px 40px;
  transition: .5s ;
  text-decoration: none;
  text-transform: uppercase;
  color: $brand;
  border: 2px solid $brand;
  border-top: none;
  border-bottom: none;
  outline: none;
  background-color: #fff;

  @include font-size(16);
  &:hover {
    color: $brand-highlight;
    border-color: $brand-highlight;
  }
}
