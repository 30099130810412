.header {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  transition: all .5s;
  background-color: transparent;
  .container {
    display: flex;
    padding-right: 10px;
    padding-left: 10px;

    align-items: center;
    justify-content: space-between;
    @include m1023() {
      padding-right: 30px;
      padding-left: 170px;

      justify-content: flex-end;
    }
  }
  &.active {
    background-color: $brand;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  }
}
.top-logo {
  display: block;
  margin-right: 20px;

  @include m425() {
    display: none;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.top-adress {
  display: flex;
  flex-direction: column;
}
.top-adress__phone {
  display: flex;
  margin-bottom: 10px;
  white-space: nowrap;

  justify-content: flex-end;
  align-items: center;
  a {
    text-decoration: none;
    color: #fff;

    @include font-size(24);
    @include m1100() {
      @include font-size(20);
    }
    span {
      font-family: $font-semibold;
    }
  }
}
.top-adress__adress {
  display: flex;
  white-space: nowrap;

  justify-content: flex-end;
  align-items: center;
  &:hover {
    svg {
      fill: $brand-highlight;
    }
  }
  a {
    font-family: $font-semibold;
    text-decoration: none;
    color: #fff;

    @include font-size(18);
    @include m1100() {
      @include font-size(14);
    }
  }
  svg {
    display: block;
    width: 20px;
    height: 25px;
    margin-right: 10px;
    transition: all .5s;

    fill: #fff;
  }
}
.header-bottom {
  padding-top: 80px;
  background-image: url('../img/bg-header-bottom.jpg');
  background-position: 50% 0;
  background-size: cover;
  .container {
    display: flex;

    justify-content: space-between;
    align-items: flex-end;
    @include m768() {
      flex-direction: column;

      align-items: flex-start;
    }
  }
}
.page-title {
  font-family: $font-bold;
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;

  @include font-size(30);
}
