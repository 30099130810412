.breadcrumbs__list {
  display: flex;
  margin: 0;
  padding: 0;
  .breadcrumbs__item:last-child {
    .breadcrumbs__link {
      text-decoration: none;
      pointer-events: none;
    }
  }
}
.breadcrumbs__item {
  position: relative;
  list-style: none;
  &::after {
    margin: 0 5px 0 6px;
    content: '/';
    color: #fff;

    @include font-size(12);
  }
  &:last-child {
    a {
      color: $brand-light;
    }
    &::after {
      display: none;
    }
  }
}
.breadcrumbs__link {
  font-family: $font-semibold;
  letter-spacing: .8px;
  color: #fff;

  @include font-size(12);
  &:hover {
    text-decoration: none;
    color: $brand-highlight;
  }
}
