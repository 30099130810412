.top-slider-section {
  position: relative;
  width: 100%;
  background-image: url('../img/bg-top-slider.jpg');
  background-size: cover;
  .container {
    padding-bottom: 0;
  }
  .top-slider {
    margin-bottom: 0;
  }
}
.top-slider-dots {
  display: flex !important;
  margin: 0;
  padding: 0;

  justify-content: center;
  li {
    margin: 0 15px;
    padding: 0;
    list-style: none;
    &.slick-active {
      button {
        background-color: $brand-light;
      }
    }
  }
  button {
    width: 30px;
    height: 4px;
    transition: background-color .5s;
    text-indent: -9999px;
    border: none;
    outline: none;
    background-color: #fff;
    &:hover {
      background-color: $brand-highlight;
    }
  }
}
.top-slider__slide {
  outline: none;
}

.top-slider__left {
  width: 100%;
  max-width: 700px;
  padding: 110px 0 60px 50px;

  @include m1100() {
    padding: 110px 0 60px 20px;
  }
  @include m1000() {
    margin: 0 auto;
    padding: 110px 20px 40px 20px;
  }
  @include m680() {
    padding: 80px 20px 30px 20px;
  }
  @include m500() {
    padding: 80px 0 30px 0;
  }
  .link-btn {
    color: #fff;
    border-color: #fff;
    &:hover {
      color: $brand-highlight;
      border-color: $brand-highlight;
    }
  }
}
.top-slider__title {
  font-family: $font-bold;
  line-height: 1;
  margin-bottom: 0;
  letter-spacing: -.72px;
  color: #fff;

  @include font-size(72);
  @include m680() {
    @include font-size(60);
  }
  @include m500() {
    @include font-size(46);
  }
}
.top-slider__subtitle {
  font-family: $font-bold;
  line-height: 1;
  letter-spacing: -.57px;
  text-transform: uppercase;
  color: #fff;

  @include font-size(57);
  @include m680() {
    @include font-size(40);
  }
  @include m500() {
    @include font-size(34);
  }
  span {
    color: $brand-light;
  }
}
.top-slider__text {
  font-family: $font-semibold;
  max-width: 500px;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #fff;

  @include font-size(14);
  @include line-height(24);
  @include m680() {
    @include font-size(12);
    @include line-height(20);
  }
}
.top-slider__slide-03 {
  .top-slider__title {
    @include font-size(60);
    @include m680() {
      @include font-size(54);
    }
  }
  .top-slider__subtitle {
    @include font-size(48);
    @include m680() {
      @include font-size(38);
    }
    @include m500() {
      @include font-size(28);
    }
  }
}
.top-slider__slide-04 {
  .top-slider__title {
    @include font-size(60);
    @include m500() {
      @include font-size(50);
    }
  }
  .top-slider__subtitle {
    @include font-size(72);
    @include m500() {
      @include font-size(50);
    }
  }
}

// begin circle slider
.top-circle__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -200%;
  left: 0;
  overflow: hidden;
  width: 100%;
  margin: auto;
  pointer-events: none;

  @include m1000() {
    display: none;
  }
  .cont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 1150px;
    margin: auto;
  }
}
.top-circle {
  position: absolute;
  z-index: 10;
  top: -520px;
  right: -1320px;
  width: 1600px;
  height: 1600px;
  margin: auto;
  transition: 1s;
  border: 170px solid rgba(43,107,166,.2);
  border-radius: 50%;
  &.active-0 {
    transform: rotate(0deg);
  }
  &.active-1 {
    transform: rotate(90deg);
  }
  &.active-2 {
    transform: rotate(180deg);
  }
  &.active-3 {
    transform: rotate(270deg);
  }
}
.top-circle__item {
  font-weight: 900;
  position: absolute;
  display: flex;
  width: 470px;
  height: 380px;
  margin: auto;
  color: #000;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  justify-content: center;
  align-items: center;
  @include font-size(20);
}
.top-circle__item-00 {
  top: 0;
  bottom: 0;
  left: -360px;
  background-image: url('../img/top-slide-01.png');
}
.top-circle__item-01 {
  right: 0;
  bottom: -280px;
  left: 0;
  transform: rotate(-90deg);
  background-image: url('../img/top-slide-02.png');
}
.top-circle__item-02 {
  top: 0;
  right: -340px;
  bottom: 0;
  transform: rotate(-180deg);
  background-image: url('../img/top-slide-03.png');
}
.top-circle__item-03 {
  top: -320px;
  right: 0;
  left: 0;
  transform: rotate(-270deg);
  background-image: url('../img/top-slide-04.png');
}
// end circle slider
