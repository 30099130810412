.footer {
  background-color: #f9fbfd;
  position: relative;
}
.footer-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  @include m425() {
    display: none;
  }

    &::before {
      position: absolute;
      bottom: -500px;
      right: 75%;
      display: block;
      width: 1500px;
      height: 1500px;
      content: '';
      border: 180px solid rgba(219,234,241,.7);
      border-radius: 50%;
    }
}
.footer__banner {
  display: flex;
  width: 100%;
  max-width: 1000px;
margin: -60px auto 0;
  align-items: center;
  @include m768() {
    flex-direction: column-reverse;
  }
  @include m425() {
    margin-top: -10px;
  }
}


// begin footer-left
.footer-left {
  position: relative;
  width: 50%;
  padding: 25px 20px 25px 30px;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(63, 148, 234, .45);
  @include m1000() {

  padding: 15px 20px 10px 30px;
  }
  @include m768() {
    width: 90%;
    max-width: 550px;
  }
}
.footer-contacts__title {
  font-family: $font-bold;
  text-transform: uppercase;
  color: $brand-light;

  @include font-size(30);
}
.footer-contacts__subtitle {
  font-family: $font-semibold;
  text-transform: none;
  color: $brand;

  @include font-size(14);
  @include line-height(18);
}
.footer-contacts__item {
  display: flex;
  margin-bottom: 10px;
  svg {
    display: block;
    width: 25px;
    height: 25px;
    margin-right: 10px;

    fill: $brand-light;
  }
}
.footer-contacts__text {
  p {
    margin: 0;
    color: $brand;

    @include font-size(14);
    @include line-height(18);
    span {
      font-family: $font-bold;
    }
  }
}
.footer-contacts__link {
  font-family: $font-semibold;
  text-decoration: none;
}
// end footer-left


// begin footer-right
.footer-right {
  display: flex;
  width: 50%;
  box-shadow: 0 0 18px rgba(63, 148, 234, .45);
  @include m768() {
    width: 100%;
    max-width: 600px;
  }
  @include m425() {
    flex-direction: column;
  }
}
.footer-form {
  width: 80%;
  padding: 35px 50px 35px 60px;
  background-color: $brand;
  @include m1000() {

  padding: 35px 30px 35px 30px;
  }
  @include m425() {
    width: 100%;
  }
}
.footer-form__title {
  font-family: $font-bold;
  text-transform: uppercase;
  color: #fff;

  @include font-size(30);
}
.footer-form__subtitle {
  font-family: $font;
  text-transform: none;
  color: #fff;

  @include font-size(14);
  @include line-height(18);
}
.footer-form__input {
  input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 5px 10px;
    border: 1px solid transparent;
    border: 1px solid $brand;
    outline: none;
    &:focus,
    &:active {
      border: 1px solid $brand-light;
    }
  }
}
.footer-form__submit {
  input {
    font-family: $font-semibold;
    padding: 5px 15px;
    transition: .5s ;
    text-transform: uppercase;
    color: #fff;
    border: 2px solid #fff;
    border-top: none;
    border-bottom: none;
    outline: none;
    background-color: transparent;

    @include font-size(16);
    &:hover {
      color: $brand-highlight;
      border-color: $brand-highlight;
    }
  }
}
.footer-social {
  display: flex;
  flex-direction: column;
  width: 20%;
  background-color: $brand-light;

  align-items: center;
  justify-content: center;
  @include m425() {
    width: 100%;
    flex-direction: row;
  }

  svg {
    display: block;
    max-width: 60px;
    max-height: 60px;
    transition: fill .5s;

    fill: $brand;
    &:hover {
      fill: $brand-highlight;
    }
  }
}
.footer-social__link {
  display: block;
  margin: 10px 20px;
}
// end footer-right


.footer-copyright {
  position: absolute;
  top: 101%;
  right: 0;
  left: 0;
  text-align: center;
  span,
  a {
    margin: 0;
    text-decoration: none;
    color: $brand;

    @include font-size(10);
  }
}

.footer-bottom {
  text-align: center;
  background-color: #fff;
}
.footer-bottom__container {
  max-width: 750px;
  margin: 0 auto;
  padding: 10px;
}
.footer-bottom__text {
  margin: 0;
  color: $brand;

  @include font-size(10);
  @include line-height(12);
}
