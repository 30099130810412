.menu-wrap {
  position: relative;
}
//==============================odinokun===========================================================
.main-menu {
  z-index: 9999;
  padding: 0;
  .menu__list {
    margin: 0;
  }
  .menu__item {
    position: relative;
    transition: .5s;
    border-left: 2px solid transparent;
    background: none;
    &:hover,
    &.sfHover {
      border-left: 2px solid $brand-highlight;
      background: none;
    }
    &.active {
      transition: .5s;
      transition-delay: .3s;
      border-left: 2px solid $brand-light;
      &:hover {
        transition-delay: 0s;
        border-left: 2px solid $brand-highlight;
      }
    }
  }
  .menu__item--parent {
    position: relative;
    padding-right: 15px;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      content: '';
      transition: .5s;
      background-color: #fff;
    }
    &::before {
      right: 27px;
      width: 10px;
      height: 2px;

      @include m1100() {
        right: 18px;
      }
    }
    &::after {
      right: 31px;
      width: 2px;
      height: 10px;

      @include m1100() {
        right: 22px;
      }
    }
    &:hover {
      background: none;
      .menu__link {
        color: $brand-highlight;
      }
      &::before,
      &::after {
        background-color: $brand-highlight;
      }
    }
  }
  .menu__sublist {
    width: auto;
    margin-left: -2px;
    padding-top: 5px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-left: 2px solid $brand-highlight;
    background-color: #fff;
  }
  .menu__subitem {
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
  .menu__link {
    font-family: $font-semibold;
    display: block;
    padding: 8px 30px 8px 10px;
    text-decoration: none;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: #fff;
    border: none;

    @include font-size(14);
    @include m1100() {
      padding: 8px 20px 8px 10px;
    }
  }
  .menu__sublink {
    font-family: $font-semibold;
    display: block;
    padding: 6px 15px 6px 10px;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;
    color: $brand;

    @include font-size(14);
  }
  .menu__link,
  .menu__sublink {
    &:hover,
    &.active {
      color: $brand-highlight;
    }
  }
}
//==================================/odinokun=======================================================
.sf-menu {
  position: static;
  float: none;
  li {
    position: static;
    white-space: normal;
  }
  > li {
    > ul {
      left: auto;
      width: 220px;
      li {
        .sf-with-ul::after {
          font-family: 'FontAwesome';
          position: absolute;
          right: 1em;
          content: '';
        }
      }
    }
  }
}

//Media Queries
@media (min-width: 1024px) {
  .toggle-mnu,
  #menu,
  html.mm-blocking #mm-blocker {
    display: none;
  }
  html.mm-opening .mm-slideout {
    transform: translate(0px, 0px);
  }
}

@media (max-width: 1023px) {
  .toggle-mnu {
    z-index: 10;
    display: block;
  }
  .sf-menu {
    display: none;
  }
}

//Custom styles
.clearfix::after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.';
}
.mm-menu.mm-effect-menu-slide {
  z-index: 9999;
}
.mm-page.mm-slideout {
  height: 100%;
}
.menu-wrap {
  margin: 0;
}
