.gallery-slide {
  display: block;
  width: 100%;
  max-width: 1200px;
  padding: 20px 0;
  outline: none;
  img {
    display: block;
    width: 100%;
    transition: 1s;

    filter: grayscale(1);
  }
  &.slick-center {
    position: relative;
    z-index: 10;
    img {
      transform: scale(1.14);

      filter: none;
      @include m768() {
        transform: none;
      }
    }
  }
}
// begin add-service-slider (edit default style)
.gallery-slider {
  width: 100% !important;
  .slick-prev,
  .slick-next {
    top: 50%;
    width: 48px;
    height: 48px;
    background-image: url('../img/slick/arrows-3.png');

    @include m375() {
      display: none !important;
    }
    &::before {
      display: none;
    }
  }
  .slick-prev {
    z-index: 10;
    left: 33%;
    background-position: 0 0;

    @include m1100() {
      left: 20px;
    }
    &:hover {
      background-position: 0 100%;
    }
  }
  .slick-next {
    right: 33%;
    background-position: 100% 0;

    @include m1100() {
      right: 20px;
    }
    &:hover {
      background-position: 100% 100%;
    }
  }
}
// end add-service-slider (edit default style)
